<template>
  <div>
    <div
      class="bg-blue-900 font-bold text-2xl w-full text-center strokeme text-white p-5 shadow-md"
    >
      แสตมป์ทั้งหมด
    </div>
    <div
      class="bg-red-700 font-bold text-xl w-full text-center strokeme text-white p-5 shadow-md"
    >
      <div class="py-5 w-full xl:w-2/3 lg:w-2/3 md:w-2/3 mx-auto">
        ขออภัย ขณะนี้กิจกรรมสะสมแลกสิทธิ์ล่องเรือ ได้รับสนับสนุนอย่างดีจากลูกค้า
        ทำให้มีผู้แลกรางวัลเต็มแล้วค่ะ
      </div>
    </div>
    <div class="content w-full xl:w-2/3 lg:w-2/3 md:w-2/3 mx-auto">
      <loading
        v-model:active="isLoading"
        :is-full-page="fullPage"
        color="#00bcb4"
      ></loading>

      <div
        class="text-blue-900 font-bold text-2xl w-full text-center strokeme p-5"
      >
        แสตมป์ของคุณ {{ custPoint }} ดวง
      </div>
      <div class="text-center mb-5 text-black">
        แสตมป์สะสมครั้งล่าสุดของท่าน<br />จะแสดงภายใน 24 ชม.
      </div>
      <div class="px-8 grid grid-cols-5 gap-4 mt-10">
        <div v-for="n in 15">
          <div
            v-if="n > custPoint"
            class="mx-auto border-white border-2 inline-flex items-center justify-center w-12 h-12 overflow-hidden bg-blue-50 rounded-full dark:bg-gray-600"
          >
            {{ n }}
          </div>
          <div
            v-else
            class="mx-auto inline-flex items-center justify-center w-12 h-12 overflow-hidden bg-blue-100 border-4 border-blue-900 rounded-full dark:bg-gray-600"
          >
            <img src="../assets/images/Stamp.png" />
          </div>
        </div>
      </div>
      <div class="text-center mt-10" v-if="custPoint == 15">
        <button
          v-if="custPoint == 15"
          @click="goTo('/redeem')"
          class="text-xl font-bold px-10 py-4 rounded-xl shadow-lg bg-yellow-800 text-white"
        >
          กดรับสิทธิ์
        </button>
      </div>
      <div class="p-5 mt-10">
        <div
          class="text-blue-900 font-bold text-3xl w-full text-center strokeme px-5 mb-5"
        >
          จำนวนรางวัลคงเหลือ
        </div>
        <div class="">
          <div v-for="(w, index) in ListReward" :key="index">
            <div
              class="border-4 border-white rounded-2xl p-5 text-center text-white bg-blue-800 mt-2"
            >
              <div class="my-3">
                <span class="font-bold text-lg" v-html="w.RW_NAME"> </span>
                <br />
                จำนวน
                {{ w.RW_STOCK }} รางวัล (จำกัด {{ w.RW_STOCK * 2 }} ท่าน/รอบ)
                <br /><br />
                <span class="text-2xl font-bold">
                  คงเหลือ
                  {{ parseInt(w.RW_STOCK) - parseInt(w.REDEEM_COUNT) }}
                  สิทธิ์</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Service from "../service/service";
export default {
  data() {
    return {
      header: null,
      content: null,
      isLoading: true,
      fullPage: true,
      custInfo: null,
      custPoint: 0,
      ListReward: null,
    };
  },
  components: {
    Loading,
  },
  service: null,
  created() {
    this.service = new Service();
  },
  async mounted() {
    await liff.ready;
    if (!liff.isLoggedIn()) {
      liff.login();
    } else {
      this.isLoading = true;
      const profile = await liff.getProfile();
      this.userLineId = profile.userId;
      this.userLineDisplayName = profile.displayName;
      this.userLinePictureUrl = profile.pictureUrl;
      await this.service.getReward().then((data) => {
        this.ListReward = data.rewardData;
      });
      await this.service.getCustomerInfo(this.userLineId).then((data) => {
        // console.log(rez.data);
        if (data.isRegisted === 1) {
          this.custInfo = data.custInfo;
          this.custPoint = data.custInfo.CUS_POINT;
          this.userIsRegister = true;
        }
      });

      this.isLoading = false;
    }
  },
  methods: {
    goTo(r) {
      window.location.replace(r);
    },
  },
};
</script>
