<template>
  <div>
    <loading
      v-model:active="isLoading"
      :is-full-page="fullPage"
      color="#00bcb4"
    ></loading>

    <div v-if="step2">
      <div
        class="bg-blue-800 font-bold text-2xl w-full text-center strokeme text-white p-5 shadow-md"
      >
        ส่งใบเสร็จร่วมกิจกรรม
      </div>
      <div class="content w-full xl:w-2/3 lg:w-2/3 md:w-2/3 mx-auto">
        <div class="p-5">
          <div class="">
            <div class="">
              <!-- <div class="text-white font-bold mt-3 mb-2">สาขาที่ซื้อ</div>
              <div class="relative flex w-full flex-wrap items-stretch mb-3">
                <select
                  v-model="submitBuyShop"
                  placeholder="กรุณาเลือกร้านค้า"
                  class="px-3 py-3 relative border-0 w-full text-black"
                >
                  <option value="">กรุณาเลือกร้านค้า</option>
                  <option
                    v-for="(s, index) in ListShops"
                    :key="index"
                    :value="s.SH_NAME"
                  >
                    {{ s.SH_NAME }}
                  </option>
                </select>
              </div>
 -->
              <div class="text-black font-bold mt-3 mb-2">สาขาที่ซื้อ</div>
              <div class="relative flex w-full flex-wrap items-stretch mb-3">
                <select
                  v-model="submitBuyShop"
                  placeholder="กรุณาเลือกสาขา"
                  class="px-3 py-3 relative border-0 w-full text-black"
                >
                  <option value="">กรุณาเลือกสาขา</option>
                  <option value="สาขาพรีเว่">สาขาพรีเว่</option>
                  <option value="สาขานอร์ธ ราชพฤกษ์">สาขานอร์ธ ราชพฤกษ์</option>
                </select>
              </div>
              <div class="text-black font-bold mt-3 mb-2">
                จำนวนเงิน มูลค่า (บาท)
              </div>
              <div class="relative flex w-full flex-wrap items-stretch mb-3">
                <input
                  v-model="inputAmount"
                  type="tel"
                  placeholder="จำนวนเงิน"
                  class="px-3 py-3 relative border-0 w-full"
                />
              </div>
              <div class="text-black font-bold mb-2">อัปโหลดใบเสร็จ</div>
              <div
                class="mt-1 flex justify-center border-2 border-blue-800 border-dashed rounded-md"
              >
                <div class="space-1 p-2 text-center">
                  <div class="flex text-sm text-black">
                    <label
                      for="file-upload"
                      class="relative cursor-pointer rounded-md font-medium"
                    >
                      <div class="w-full py-5" v-if="submitPicUrl == null">
                        <svg
                          class="mx-auto h-12 w-12 text-black"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        <div class="text-black" v-if="submitPicUrl == null">
                          อัปโหลดหรือถ่ายภาพใบเสร็จ
                        </div>
                      </div>
                      <div v-if="submitPicUrl" class="text-center">
                        <img :src="submitPicUrl" style="width: 100%" />
                        <!-- <div
                  v-if="submitPicUrl"
                  class="mt-3 uppercase overflow-ellipsis"
                >
                  {{ submitPicName }}
                </div> -->
                        <div class="mt-3 text-white">
                          กดที่รูปเพื่อเปลี่ยนภาพ
                        </div>
                      </div>

                      <input
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        class="sr-only"
                        @change="onFileChange"
                      />
                    </label>
                  </div>
                  <p class="text-xs text-gray-500"></p>
                </div>
              </div>

              <!-- -->
              <!-- <div class="text-gray-700 font-bold mb-2">วันที่ซื้อ</div>
              <div class="relative flex w-full flex-wrap items-stretch mb-3">
                <span
                  class="z-10 h-full absolute text-center text-blue-700 absolute bg-transparent rounded items-center justify-center w-8 pl-3 py-3"
                >
                  <CalendarIcon class="ml-1 h-6 w-6 " />
                </span>
                <input
                  v-model="submitBuyDate"
                  type="date"
                  placeholder="วันที่ซื้อ"
                  class="px-3 py-3 relative   rounded-full  border border-gray-300 outline-none focus:outline-none focus:ring w-full pl-12"
                />
              </div> -->
              <!-- -->
            </div>
          </div>
          <div class="flex flex-wrap mx-auto mt-5">
            <button
              @click="goSubmit"
              :disabled="btnDisable"
              class="w-full text-2xl font-bold px-3 py-4 rounded-lg shadow-lg bg-yellow-800 text-white"
            >
              กดส่งใบเสร็จ
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- -->
  </div>

  <!-- modal -->

  <div v-if="showModal">
    <div
      className="fixed inset-0 w-full z-50 bg-black bg-opacity-50  duration-300 overflow-y-auto"
    >
      <div
        className="relative w-11/12  sm:w-full md:w-1/2 lg:w-1/2 mx-2 sm:mx-auto mt-10 mb-3 opacity-100"
      >
        <div className="relative  shadow-lg rounded text-gray-900 z-20">
          <button
            @click="showModal = false"
            class="absolute -top-3 -right-3 bg-blue-700 hover:bg-blue-900 text-2xl w-10 h-10 rounded-full focus:outline-none text-white"
          >
            &cross;
          </button>
          <div class="p-5">
            <div class="font-semibold text-gray-700 text-xl font-extrabold">
              ตัวอย่างใบเสร็จ
            </div>
            <div className="text-center mx-auto leading-none"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div
        class="z-40 fixed inset-0 bg-black bg-opacity-50 h-screen w-full flex justify-center items-start md:items-center pt-10 md:pt-0"
      >
        <div
          class="z-50 pacity-0 transform relative w-11/12 md:w-1/2   rounded shadow-lg transition-opacity transition-transform duration-300"
          style="max-height: 80vh;"
        >
          <button
            @click="showModal = false"
            class="absolute -top-3 -right-3 bg-blue-700 hover:bg-blue-900 text-2xl w-10 h-10 rounded-full focus:outline-none text-white"
          >
            &cross;
          </button>
          <div class="p-5">
            <h2 class="font-semibold text-gray-700 text-xl font-extrabold">
              ตัวอย่างใบเสร็จ
            </h2>
            
          </div>
        </div>
      </div> -->
  </div>
</template>
<style scoped>
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
ul,
li {
  list-style: none;
  margin-left: 0 !important;
}
.max-height-48 {
  max-height: 200px;
}
</style>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import {
  ClipboardCopyIcon,
  ShoppingCartIcon,
  // CalendarIcon,
} from "@heroicons/vue/outline";
import liff from "@line/liff";
import router from "../router";
import Service from "../service/service";
import configs from "../config";
export default {
  name: "Submit",
  data() {
    return {
      userLineId: null,
      userIsRegister: false,
      isLoading: true,
      fullPage: true,
      submitBuyDate: null,
      submitBuyAmount: "",
      submitBuyShop: "",

      submitBillNo: null,
      submitProduct: null,
      submitPic: null,
      submitPicUrl: null,
      submitPicName: null,
      showModal: false,
      shopList: null,
      branchList: null,
      shopListChilds: null,
      showShops: false,
      configs,
      userCoordinates: null,
      btnDisable: false,
      step1: false,
      step2: true,
      step3: false,
      shopType: "",
      product: "",
      shopInfo: null,
      minBill: 0,

      ListProvinces: null,
      ListShops: null,
      ListBranches: null,
      inputProvince: "",
      inputAmount: "",
      inputShopName: "",
      custInfo: null,
    };
  },
  components: {
    Loading,
    ClipboardCopyIcon,
    // CalendarIcon,
    ShoppingCartIcon,
  },
  service: null,
  created() {
    let today = new Date();
    let tomonth = today.getMonth() + 1;
    let month = tomonth < 10 ? "0" + tomonth : tomonth;
    let todate = today.getDate() < 10 ? "0" + today.getDate() : today.getDate();
    this.service = new Service();
    this.submitBuyDate = today.getFullYear() + "-" + month + "-" + todate;

    this.$getLocation()
      .then((coordinates) => {
        this.userCoordinates = coordinates;
        // console.log(coordinates);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async mounted() {
    this.getShop();
    await liff.ready;
    if (!liff.isLoggedIn()) {
      liff.login();
    } else {
      this.isLoading = true;
      const profile = await liff.getProfile();
      this.userLineId = profile.userId;
      await this.service.getCampaignInfo().then((data) => {
        console.log("submit: get-campaign-info =>", data);
        if (data.campaignInfo.C_ONLINE !== 1) {
          router.push("/off");
        } else {
          // console.log(data.campaign);
          // this.minBill = data.campaign.CONFIG.BILL_MIN;
          this.service.getCustomerInfo(this.userLineId).then((data) => {
            console.log("submit: get-customer-info =>", data);
            if (data.isRegisted === 1) {
              this.custInfo = data.custInfo;
              // console.log(data.custInfo);
              // this.goStep1();
            } else {
              this.$swal
                .fire({
                  html: "คุณยังไม่ได้ลงทะเบียน<br />กรุณาลงทะเบียนก่อนร่วมกิจกรรม",
                  icon: "error",
                  showCancelButton: false,
                  confirmButtonColor: "#b91c1c",

                  confirmButtonText: "ไปลงทะเบียน  &rarr;",
                })
                .then((result) => {
                  if (result.isConfirmed) {
                    router.push("/register");
                  }
                });
            }
            this.isLoading = false;
          });
        }
      });
      // await this.service.getProvince().then((data) => {
      // this.ListProvinces = data.data;
      // });
      // this.getBranch();
    }
  },
  methods: {
    liffCloseWindow() {
      liff.closeWindow();
    },
    async getShop() {
      await this.service.getShopName().then((data) => {
        this.ListShops = data.shop;
      });
    },
    async getBranch() {
      await this.service.getShopBranch().then((data) => {
        console.log(data);
        this.ListBranches = data.shop;
      });
    },
    onFileChange(e) {
      this.submitPic = e.target.files[0];
      this.submitPicName = e.target.files[0].name;
      this.submitPicUrl = URL.createObjectURL(this.submitPic);
    },

    onShopChange(id) {
      console.log(id);
      this.service.getShopInfo(id).then((data) => {
        this.shopListChilds = data.shopChild;
      });
    },
    async goSubmit() {
      let isError = false;
      let txtError = "";
      this.btnDisable = true;
      // if (this.submitBuyShop === null || this.submitBuyShop === "") {
      //   isError = true;
      //   txtError += "<li>กรุณาระบุร้านค้าที่ท่านซื้อ</li>";
      // }
      if (this.submitBuyShop === null || this.submitBuyShop === "") {
        isError = true;
        txtError += "<li>กรุณาระบุสาขา</li>";
      }

      this.minBill = 1000;

      if (
        this.inputAmount === null ||
        this.inputAmount === "" ||
        this.inputAmount == 0
      ) {
        isError = true;
        txtError += "<li>กรุณาระบุจำนวนเงินที่ซื้อ</li>";
        // } else {
        //   console.log(this.inputAmount);
        //   console.log(parseInt(this.minBill));
        //   if (this.inputAmount < parseInt(this.minBill)) {
        //     isError = true;
        //     txtError +=
        //       "<li>ใบเสร็จมียอดการซื้อต่ำกว่าเงื่อนไขที่สามารถร่วมกิจกรรมได้ (ขั้นต่ำ " +
        //       this.minBill +
        //       " บาท)</li>";
        //   }
      }

      if (this.submitPic === null || this.submitPic === "") {
        isError = true;
        txtError += "<li>กรุณาถ่ายรูปหรืออัปโหลดใบเสร็จ</li>";
      }

      if (!isError) {
        // upload image first
        this.isLoading = true;
        let imgFormData = new FormData();
        imgFormData.append("image", this.submitPic);
        await this.service
          .uploadBill(imgFormData)
          .then((data) => {
            console.log(data);
            if (data.isSuccess === 1) {
              let formData = new FormData();
              formData.append("line_id", this.userLineId);
              formData.append("bill_pic", data.uploadResult);
              formData.append("bill_amount", this.inputAmount);

              formData.append("bill_shop", this.submitBuyShop);
              // formData.append("bill_shop_sub", this.submitBuyShopSub);
              // if (this.shopType === "sales-floor") {
              //   formData.append("bill_no", this.inputBillNo);
              // }
              // formData.append("bill_type", this.shopType);
              if (this.userCoordinates) {
                formData.append(
                  "bill_geolocation",
                  this.userCoordinates.lat + "," + this.userCoordinates.lng
                );
              } else {
                formData.append("bill_geolocation", "0,0");
              }

              this.service
                .submitBill(formData)
                .then((rez) => {
                  console.log(rez.data);
                  if (rez.data.isSuccess === 1) {
                    router.push("/success");

                    this.isLoading = false;
                  } else {
                    this.isLoading = false;
                    this.btnDisable = false;
                    this.$swal({
                      html: rez.data.msgError,
                      title: "พบข้อผิดพลาด",
                      icon: "error",
                      showConfirmButton: false,
                      showCancelButton: true,
                      cancelButtonColor: "#b91c1c",
                      cancelButtonText: "ตกลง",
                    });
                  }
                })
                .catch((error) => console.error("member-submit-bill", error));
            } else {
              this.$swal({
                html: "<ul>" + data.uploadResult + "</ul>",
                title: "พบข้อผิดพลาด",
                icon: "error",
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonColor: "#b91c1c",
                cancelButtonText: "ตกลง",
              });
              this.isLoading = false;
              this.btnDisable = false;
            }
          })
          .catch((error) => console.error("s3-upload", error));

        // submit to backend
      } else {
        this.isLoading = false;
        this.btnDisable = false;
        this.$swal({
          html: "<ul>" + txtError + "</ul>",
          title: "พบข้อผิดพลาด",
          icon: "error",
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonColor: "#b91c1c",
          cancelButtonText: "ตกลง",
        });
      }
    },
  },
};
</script>
<style scoped>
.divider-text {
  position: relative;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #000;
  letter-spacing: 0.5px;
  margin: 15px 0;
}
.divider-text::before,
.divider-text::after {
  content: "";
  display: block;
  flex: 1;
  height: 1px;
  background-color: #e5e9f2;
}
.divider-text::before {
  margin-right: 20px;
}
.divider-text::after {
  margin-left: 20px;
}
</style>
