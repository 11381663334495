<template>
  <div>
    <div
      class="bg-blue-800 font-bold text-2xl w-full text-center strokeme text-white p-5 shadow-md"
    >
      ดำเนินการสำเร็จ
    </div>
    <div class="content w-full xl:w-2/3 lg:w-2/3 md:w-2/3 mx-auto">
      <loading
        v-model:active="isLoading"
        :is-full-page="fullPage"
        color="#00bcb4"
      ></loading>

      <div class="p-5">
        <div
          class="bg-white text-black p-5 rounded-xl shadow-md text-center py-10"
        >
          <center>
            <img
              src="../assets/images/icon-ok.png"
              style="width: 100px"
            /><br />
            <h1 class="font-bold text-xl text-blue-800">
              ส่งใบเสร็จเรียบร้อยแล้ว
            </h1>
            แสตมป์สะสมในครั้งนี้จะแสดงภายใน 24 ชม.<br /><br />
            <button
              @click="goTo('/submit')"
              class="text-xl font-bold px-10 py-4 rounded-xl shadow-lg bg-yellow-800 text-white"
            >
              ส่งใบเสร็จร่วมกิจกรรม
            </button>
          </center>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import liff from "@line/liff";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Service from "../service/service";
export default {
  name: "Success",
  data() {
    return {
      header: null,
      content: null,
      isLoading: false,
      fullPage: true,
    };
  },
  components: {
    Loading,
  },
  service: null,
  created() {
    this.service = new Service();
  },
  async mounted() {
    await liff.ready;
    if (!liff.isLoggedIn()) {
      liff.login();
    }
  },
  methods: {
    liffCloseWindow() {
      liff.closeWindow();
    },
    goTo(r) {
      window.location.replace(r);
    },
  },
};
</script>
