import { createRouter, createWebHistory } from "vue-router";
import Register from "../views/Register.vue";
import Submit from "../views/Submit.vue";
import Success from "../views/Success.vue";
import Condition from "../views/Condition.vue";
import History from "../views/History.vue";
import Coupon from "@/views/Coupon.vue";
import Redeem from "@/views/Redeem.vue";
import Home from "@/views/Home.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/register",
    component: Register,
    props: { coupon: false },
  },

  {
    path: "/submit",
    component: Submit,
  },
  {
    path: "/success",
    component: Success,
  },

  {
    path: "/condition",
    component: Condition,
  },
  {
    path: "/history",
    component: History,
  },

  {
    path: "/coupon",
    component: Coupon,
  },
  {
    path: "/redeem",
    component: Redeem,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
