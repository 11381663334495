<template>
  <div>
    <div
      class="bg-blue-800 font-bold text-2xl w-full text-center strokeme text-white p-5 shadow-md"
    >
      ประวัติการส่งใบเสร็จ
    </div>
    <div class="content w-full xl:w-2/3 lg:w-2/3 md:w-2/3 mx-auto">
      <loading
        v-model:active="isLoading"
        :is-full-page="fullPage"
        color="#00bcb4"
      ></loading>
      <div v-if="userIsRegister">
        <div class="">
          <div class="p-5 rounded-xl text-black">
            <div class="flex flex-col text-center">
              <img
                :src="userLinePictureUrl"
                alt=""
                class="rounded-full h-28 w-28 my-5 mx-auto border-4 border-white"
              />

              <div class="text-2xl text-normal font-bold">
                {{ userLineDisplayName }}
              </div>
              <div class="text-xl">คุณ{{ custInfo.CUS_FNAME }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="p-5">
        <div class="bg-white rounded-lg shadow-md">
          <div class="overflow-x-auto rounded-lg overflow-y-auto relative">
            <table
              class="border-collapse w-full whitespace-no-wrap bg-white table-striped relative"
            >
              <thead>
                <tr class="text-left text-sm">
                  <th
                    class="bg-blue-800 text-center sticky text-white top-0 border-b border-gray-200 px-3 py-2 whitespace-nowrap"
                  >
                    วันที่ส่งใบเสร็จ
                  </th>
                  <th
                    class="bg-blue-800 text-center sticky text-white top-0 border-b border-gray-200 px-3 py-2 whitespace-nowrap"
                  >
                    วันที่ใบเสร็จ
                  </th>

                  <th
                    class="bg-blue-800 text-center sticky text-white top-0 border-b border-gray-200 px-3 py-2 whitespace-nowrap"
                  >
                    เลขที่ใบเสร็จ
                  </th>
                  <th
                    class="bg-blue-800 text-center sticky text-white top-0 border-b border-gray-200 px-3 py-2 whitespace-nowrap"
                  >
                    จำนวนเงิน
                  </th>

                  <th
                    class="bg-blue-800 text-center sticky text-white top-0 border-b border-gray-200 px-3 py-2 whitespace-nowrap"
                  >
                    สถานะ
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(h, index) in custHistory" :key="index">
                  <td
                    class="text-sm border-b border-gray-200 px-4 py-2 whitespace-nowrap"
                  >
                    {{ h.CB_SUBMIT_DATETIME }}
                  </td>
                  <td
                    class="text-sm border-b border-gray-200 px-4 py-2 whitespace-nowrap"
                  >
                    {{ h.CB_APPROVE_BUY_DATE }}
                  </td>
                  <td
                    class="text-sm border-b border-gray-200 px-4 py-2 whitespace-nowrap"
                  >
                    {{
                      h.CB_APPROVE_BILL_NO
                        ? h.CB_APPROVE_BILL_NO
                        : h.CB_SUBMIT_BILL_NO
                    }}
                  </td>

                  <td
                    class="text-sm border-b border-gray-200 px-4 py-2 whitespace-nowrap text-right"
                  >
                    {{
                      h.CB_APPROVE_STATUS === "APPROVED"
                        ? h.CB_APPROVE_BUY_AMOUNT
                        : h.CB_SUBMIT_BUY_AMOUNT
                    }}
                  </td>

                  <td
                    class="text-sm border-b border-gray-200 px-4 py-2 whitespace-nowrap"
                  >
                    {{ h.CB_APPROVE_STATUS_TEXT }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Service from "../service/service";
export default {
  name: "History",
  data() {
    return {
      header: null,
      content: null,
      isLoading: false,
      fullPage: true,
      custInfo: null,
    };
  },
  components: {
    Loading,
  },
  service: null,
  created() {
    this.service = new Service();
  },
  async mounted() {
    await liff.ready;
    if (!liff.isLoggedIn()) {
      liff.login();
    } else {
      this.isLoading = true;
      const profile = await liff.getProfile();
      this.userLineId = profile.userId;
      this.userLineDisplayName = profile.displayName;
      this.userLinePictureUrl = profile.pictureUrl;
      await this.service.getCustomerInfo(this.userLineId).then(async (data) => {
        if (data.isRegisted === 1) {
          this.userIsRegister = true;
          this.custInfo = data.custInfo;
          await this.service.getBillHistory(this.userLineId).then((data) => {
            console.log(data);
            this.custHistory = data.historyData;
          });
        } else {
          this.$swal
            .fire({
              html: "คุณยังไม่ได้ลงทะเบียน<br />กรุณาลงทะเบียนก่อนร่วมกิจกรรม",
              icon: "error",
              showCancelButton: false,
              confirmButtonColor: "#b91c1c",

              confirmButtonText: "ไปลงทะเบียน  &rarr;",
            })
            .then((result) => {
              if (result.isConfirmed) {
                router.push("/register");
              }
            });
        }
        this.isLoading = false;
      });
    }
  },
};
</script>
