<template>
  <div
    enter-class="opacity-0"
    enter-active-class="ease-out transition-medium"
    enter-to-class="opacity-100"
    leave-class="opacity-100"
    leave-active-class="ease-out transition-medium"
    leave-to-class="opacity-0"
  >
    <div v-if="drawerOpen" class="z-40 fixed inset-0 transition-opacity">
      <div
        class="absolute inset-0 bg-gray-800 opacity-80"
        tabIndex="{0}"
        @click="drawerTigger"
      />
    </div>
  </div>
  <div class="bg-white">
    <img src="../assets/images/logo-lotuss.png" class="mx-auto h-20" />
  </div>
  <div id="footer" class="z-40 fixed pt-1 bottom-0 left-0 right-0 w-full flex">
    <div class="bg-white w-full flex px-2">
      <div
        class="flex flex-grow w-1/5 items-center justify-center py-2 cursor-pointer"
        @click="goTo('/submit')"
      >
        <div class="text-center w-full">
          <div class="text-yellow-800 text-xl">
            <i class="bi bi-camera"></i>
          </div>

          <div class="text-xs text-yellow-800 rounded-full pt-0">
            ส่งใบเสร็จ
          </div>
        </div>
      </div>
      <div
        class="flex flex-grow w-1/5 items-center justify-center py-2 cursor-pointer"
        @click="goTo('/condition')"
      >
        <div class="text-center w-full">
          <div class="text-yellow-800 text-xl">
            <i class="bi bi-blockquote-right"></i>
          </div>
          <div class="text-xs text-yellow-800 rounded-full pt-0">เงื่อนไข</div>
        </div>
      </div>
      <div class="flex flex-grow w-1/5 items-center justify-center py-2">
        <router-link
          to="/"
          class="bg-blue-800 rounded-full mt-n4 px-4 py-3 shadow-lg"
          style="margin-top: -40px"
        >
          <div class="text-center w-full">
            <div class="text-yellow-800 text-2xl">
              <i class="bi bi-house"></i>
            </div>
          </div>
        </router-link>
      </div>
      <div
        class="flex flex-grow w-1/5 items-center justify-center py-2 cursor-pointer"
        @click="goTo('/coupon')"
      >
        <div class="text-center w-full">
          <div class="text-yellow-800 text-xl">
            <i class="bi bi-award"></i>
          </div>
          <div class="text-xs text-yellow-800 rounded-full pt-0">
            แสตมป์ของคุณ
          </div>
        </div>
      </div>
      <div
        class="flex flex-grow w-1/5 items-center justify-center py-2"
        @click="goTo('/history')"
      >
        <div class="text-center w-full">
          <img
            :src="userLinePictureUrl"
            alt=""
            class="mb-2 rounded-full mx-auto border-2 border-yellow-800"
            style="width: 30px; height: 30px"
          />

          <div class="text-xs text-yellow-800 rounded-full -mt-2">
            ประวัติการส่ง
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { version } from "../../package";
import liff from "@line/liff";
import Service from "../service/service";
export default {
  data() {
    return {
      drawerOpen: false,
      appVersion: version,
      isDone: false,
      userLineDisplayName: "",
      userLinePictureUrl: "",
      userIsRegister: false,
    };
  },
  mounted() {},

  async created() {
    this.service = new Service();
    await liff.ready;
    if (!liff.isLoggedIn()) {
      liff.login();
    } else {
      const profile = await liff.getProfile();
      this.userLineDisplayName = profile.displayName;
      this.userLinePictureUrl = profile.pictureUrl;

      await this.service.getCustomerInfo(profile.userId).then((data) => {
        console.log("layout: get-customer-info =>", data);
        if (data.isRegisted === 1) {
          this.custInfo = data.custInfo;
          this.userIsRegister = true;
        }
      });
      this.isDone = true;
    }
  },
  computed: {},
  methods: {
    drawerTigger() {
      // console.log("vue-layout:", this.drawerOpen);
      this.drawerOpen = !this.drawerOpen;
    },
    goTo(r) {
      window.location.replace(r);
    },
  },
};
</script>
<style scoped>
#footer img {
  width: 45px;
  height: 48px;
}
#footer img.profile {
  width: 40px;
  height: 40px;
}
</style>
