<template>
  <div>
    <div
      class="bg-blue-800 font-bold text-2xl w-full text-center strokeme text-white p-5 shadow-md"
    >
      ลงทะเบียนสะสมแสตมป์
    </div>
    <div class="content w-full xl:w-2/3 lg:w-2/3 md:w-2/3 mx-auto">
      <Loading
        v-model:active="isLoading"
        :is-full-page="true"
        color="#00bcb4"
      ></Loading>
      <div v-if="!isLoading">
        <div v-if="!userIsRegister">
          <div class="p-5">
            <div class="rounded-xl">
              <div class="relative flex w-full flex-wrap items-stretch mb-3">
                <label class="text-black font-bold mb-2">ชื่อ</label>

                <input
                  v-model="inputFName"
                  type="text"
                  placeholder="กรอกชื่อ"
                  :disabled="disableInputFName"
                  class="px-3 py-3 relative border-0 w-full"
                />
              </div>
              <div class="relative flex w-full flex-wrap items-stretch mb-3">
                <label class="text-black font-bold mb-2">นามสกุล</label>

                <input
                  v-model="inputLName"
                  type="text"
                  placeholder="กรอกนามสกุล"
                  :disabled="disableInputLName"
                  class="px-3 py-3 relative border-0 w-full"
                />
              </div>

              <div class="relative flex w-full flex-wrap items-stretch mb-3">
                <label class="text-black font-bold mb-2">เบอร์โทรศัพท์</label>

                <input
                  v-model="inputMobile"
                  v-mask="'###-###-####'"
                  type="tel"
                  placeholder="เบอร์มือถือ 10 หลัก"
                  :disabled="disableInputMobile"
                  class="px-3 py-3 relative border-0 w-full"
                />
              </div>

              <div class="mt-10">
                <div class="mt-2">
                  <div class="mt-1">
                    <label class="inline-flex">
                      <input
                        type="checkbox"
                        class="form-checkbox text-blue-800 border-0 h-6 w-6 mt-1 bg-gray-50"
                        v-model="inputAccept1"
                        @click="showModalAccept1 = true"
                      />
                      <span class="ml-3 mr-5 leading-5 text-black"
                        >ข้าพเจ้าได้อ่านและยอมรับ
                        <u>นโยบายการคุ้มครองข้อมูลส่วนบุคคล</u> แล้ว</span
                      >
                    </label>
                  </div>
                  <div class="mt-3">
                    <label class="inline-flex">
                      <input
                        type="checkbox"
                        class="form-checkbox text-blue-800 border-0 h-6 w-6 mt-1 bg-gray-100"
                        v-model="inputAccept2"
                        @click="showModalAccept2 = true"
                      />
                      <span class="ml-3 mr-5 leading-5 text-black"
                        >ข้าพเจ้าได้อ่านและยอมรับ
                        <u>เงื่อนไขและกติกาการร่วมกิจกรรม</u> แล้ว</span
                      >
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap mx-auto px-5">
            <button
              @click="goSubmit"
              class="w-full text-2xl font-bold px-3 py-4 rounded-xl shadow-lg bg-blue-800 text-white"
            >
              ยืนยันการลงทะเบียน
            </button>
          </div>
        </div>
        <div v-if="userIsRegister">
          <div class="p-5">
            <div class="bg-white p-5 rounded-xl p-5 shadow-md">
              <div class="text-center text-black">
                <img
                  :src="userLinePictureUrl"
                  alt=""
                  class="rounded-full h-28 w-28 my-10 mx-auto"
                />

                <div class="text-2xl text-normal font-bold">
                  {{ userLineDisplayName }}
                </div>
                <div class="text-xl">คุณ{{ custInfo.CUS_FNAME }}</div>
                <div
                  class="text-2xl font-bold font-extrabold text-blue-800 mb-5"
                >
                  ลงทะเบียนสำเร็จ!
                </div>
                <button
                  @click="goTo('/submit')"
                  class="text-xl font-bold px-10 py-4 rounded-xl shadow-lg bg-yellow-800 text-white"
                >
                  ส่งใบเสร็จร่วมกิจกรรม
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- modal -->
      <div v-if="showModalAccept1">
        <div
          class="z-40 fixed inset-0 bg-black bg-opacity-50 h-screen w-full flex justify-center items-start md:items-center pt-10 md:pt-0"
        >
          <div
            class="bg-white z-50 pacity-0 transform relative w-11/12 md:w-1/2 rounded shadow-lg transition-opacity transition-transform duration-300"
            style="height: 80vh"
          >
            <button
              @click="showModalAccept1 = false"
              class="absolute -top-3 -right-3 bg-red-800 hover:bg-red-900 text-2xl w-10 h-10 rounded-full focus:outline-none text-white"
            >
              &cross;
            </button>
            <button
              @click="showModalAccept1 = false"
              class="absolute -bottom-5 -left-1 w-full px-10 text-2xl font-bold px-3 py-2 m-1 rounded-b border-b-4 border-l-2 shadow-lg bg-blue-800 border-blue-800 text-white"
            >
              ยอมรับ
            </button>
            <div class="p-5 px-7">
              <h2 class="font-bold text-blue-800 text-xl">
                {{ headerAccept1 }}
              </h2>
            </div>

            <div
              class="text-sm w-full px-7 pb-20 overflow-x-hidden overflow-y-auto"
              style="height: 65vh"
            >
              <div v-html="contentAccept1"></div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showModalAccept2">
        <div
          class="z-40 fixed inset-0 bg-black bg-opacity-50 h-screen w-full flex justify-center items-start md:items-center pt-10 md:pt-0"
        >
          <div
            class="bg-white z-50 pacity-0 transform relative w-11/12 md:w-1/2 rounded shadow-lg transition-opacity transition-transform duration-300"
            style="height: 80vh"
          >
            <button
              @click="showModalAccept2 = false"
              class="absolute -top-3 -right-3 bg-red-800 hover:bg-red-900 text-2xl w-10 h-10 rounded-full focus:outline-none text-white"
            >
              &cross;
            </button>
            <button
              @click="showModalAccept2 = false"
              class="absolute -bottom-5 -left-1 w-full px-10 text-2xl font-bold px-3 py-2 m-1 rounded-b border-b-4 border-l-2 shadow-lg bg-blue-800 border-blue-800 text-white"
            >
              ยอมรับ
            </button>
            <div class="p-5 px-7">
              <h2 class="font-bold text-blue-800 text-xl">
                {{ headerAccept2 }}
              </h2>
            </div>

            <div
              class="text-sm w-full px-7 pb-20 overflow-x-hidden overflow-y-auto"
              style="height: 65vh"
            >
              <div v-html="contentAccept2"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- end of modal -->
    </div>
  </div>
</template>
<script>
import liff from "@line/liff";
import { UserIcon, DeviceMobileIcon } from "@heroicons/vue/outline";
import router from "../router";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Service from "../service/service";
function unmask(maskedValue, mask) {
  let defaultTokens = ["#", "X", "S", "A", "a", "!"];
  let unmaskedValue = "";
  let isToken;
  for (let i = 0; i < maskedValue.length; i++) {
    isToken = false;
    for (let j = 0; j < defaultTokens.length; j++) {
      if (mask[i] == defaultTokens[j]) {
        isToken = true;
      }
    }
    if (isToken) {
      unmaskedValue += maskedValue[i];
    }
  }
  return unmaskedValue;
}
export default {
  name: "History",
  props: ["coupon"],
  data() {
    return {
      isLoading: true,
      userLineId: null,
      userLineDisplayName: null,
      userLinePictureUrl: null,
      userIsRegister: false,
      custInfo: null,
      couponSerial: "",
      couponDiscount: this.coupon,
      couponMessage: "",

      showModalAccept1: false,
      showModalAccept2: false,

      headerAccept1: null,
      headerAccept2: null,
      contentAccept1: null,
      contentAccept2: null,

      inputFName: "",
      inputLName: "",
      inputMobile: "",
      inputMobileRaw: "",
      inputShopName: "",
      inputShopBranch: "",
      inputProvince: "",
      inputAmount: "",
      disableInputFName: false,
      disableInputMobile: false,
      disableShopBranch: true,

      ListProvince: null,
      ListShop: null,
      ListBranche: null,

      campaignInfo: null,
    };
  },
  components: {
    Loading,
    UserIcon,
    DeviceMobileIcon,
  },
  service: null,
  created() {
    this.service = new Service();
  },
  async mounted() {
    await liff.ready;
    if (!liff.isLoggedIn()) {
      liff.login();
    } else {
      this.isLoading = true;
      const profile = await liff.getProfile();
      this.userLineId = profile.userId;
      this.userLineDisplayName = profile.displayName;
      this.userLinePictureUrl = profile.pictureUrl;
      await this.service.getCampaignInfo().then((data) => {
        this.campaignInfo = data.campaignInfo;
      });

      await this.service.getCustomerInfo(this.userLineId).then((data) => {
        // console.log(rez.data);
        if (data.isRegisted === 1) {
          this.custInfo = data.custInfo;
          this.disableInputFName = true;
          this.disableInputLName = true;
          this.disableInputMobile = true;
          this.inputFName = data.custInfo.CUS_FNAME;
          this.inputMobile = data.custInfo.CUS_MOBILE;
          this.userIsRegister = true;
        } else {
          this.userIsRegister = false;
          if (this.campaignInfo.C_ONLINE !== 1) {
            router.push("/off");
          }
        }
      });

      await this.service.getContent("PDPA").then((data) => {
        if (data.contentData) {
          this.headerAccept1 = data.contentData.PC_HEADER;
          this.contentAccept1 = data.contentData.PC_BODY;
        }
      });
      await this.service.getContent("CONDITION").then((data) => {
        if (data.contentData) {
          this.headerAccept2 = data.contentData.PC_HEADER;
          this.contentAccept2 = data.contentData.PC_BODY;
        }
      });

      this.isLoading = false;
    }
  },
  methods: {
    liffCloseWindow() {
      liff.closeWindow();
    },
    goTo(r) {
      window.location.replace(r);
    },
    async goSubmit() {
      let isError = false;
      let txtError = "";
      if (this.inputFName === "") {
        isError = true;
        txtError += "<li>กรุณากรอกชื่อ</li>";
      }
      if (this.inputLName === "") {
        isError = true;
        txtError += "<li>กรุณากรอกนามสกุล</li>";
      }
      if (this.inputMobile === "") {
        isError = true;
        txtError += "<li>กรุณากรอกเบอร์มือถือ</li>";
      } else {
        this.inputMobileRaw = unmask(
          this.inputMobile,
          "###-###-####"
        ).toString();

        if (this.inputMobileRaw.length != 10) {
          isError = true;
          txtError += "<li>เบอร์มือถือไม่ถูกต้อง</li>";
        } else {
          await this.service
            .isMobileDuplicate(this.userLineId, this.inputMobileRaw)
            .then((data) => {
              // console.log(data.isDup);
              if (data.isDup === 1) {
                //

                isError = true;
                txtError += "<li>เบอร์นี้มีการลงทะเบียนไว้แล้ว</li>";
              }
            });
        }
      }
      if (!this.inputAccept1) {
        isError = true;
        txtError += "<li>กรุณายอมรับนโยบายการคุ้มครองข้อมูล</li>";
      }
      if (!this.inputAccept2) {
        isError = true;
        txtError += "<li>กรุณายอมรับกติกาการร่วมกิจกรรม</li>";
      }
      if (!isError) {
        let formData = new FormData();
        formData.append("lineid", this.userLineId);
        formData.append("fname", this.inputFName);
        formData.append("lname", this.inputLName);
        formData.append("mobile", this.inputMobileRaw);

        formData.append(
          "accept",
          JSON.stringify({
            PDPA: this.inputAccept1,
            CONDITION: this.inputAccept2,
          })
        );
        this.service.submitRegister(formData).then((data) => {
          if (data.isSuccess === 1) {
            //this.custInfo = data.custInfo;
            this.userIsRegister = true;

            window.location.reload();
          } else {
            this.$swal({
              html: data.txtMessage,
              title: "พบข้อผิดพลาด",
              icon: "error",
              showCancelButton: true,
              showConfirmButton: false,
              cancelButtonColor: "#991b1b",

              cancelButtonText: "ตกลง",
            });
          }
        });
      } else {
        this.$swal({
          html: "<ul>" + txtError + "</ul>",
          title: "พบข้อผิดพลาด",
          icon: "error",
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonColor: "#991b1b",
          cancelButtonText: "ตกลง",
        });
      }
    },
  },
};
</script>
