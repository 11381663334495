<template>
  <div>
    <div
      class="bg-blue-800 font-bold text-2xl w-full text-center strokeme text-white p-5 shadow-md"
    >
      แลกสิทธิ์สะสมแสตมป์
    </div>
    <div class="content w-full xl:w-2/3 lg:w-2/3 md:w-2/3 mx-auto">
      <Loading
        v-model:active="isLoading"
        :is-full-page="true"
        color="#00bcb4"
      ></Loading>
      <div v-if="!isLoading">
        <div v-if="userIsRegister">
          <div v-if="!userIsRedeem">
            <div class="px-5">
              <div v-for="(w, index) in ListReward" :key="index">
                <div
                  class="border-4 border-white rounded-2xl p-5 text-center text-white bg-blue-800 mt-5"
                >
                  <div class="my-3">
                    <span class="font-bold text-lg" v-html="w.RW_NAME"> </span>
                    <br />
                    จำนวน
                    {{ w.RW_STOCK }} รางวัล (จำกัด
                    {{ w.RW_STOCK * 2 }} ท่าน/รอบ)
                  </div>
                  <button
                    v-if="
                      parseInt(w.RW_STOCK) - parseInt(w.REDEEM_COUNT) > 0 &&
                      custInfo.CUS_POINT >= 15
                    "
                    @click="goSubmit(w)"
                    class="text-xl font-bold px-5 py-2 rounded-lg shadow-lg bg-yellow-800 text-white"
                  >
                    เลือก
                  </button>
                  <button
                    v-else
                    class="text-xl font-bold px-5 py-2 rounded-xl shadow-lg bg-gray-300 text-white"
                  >
                    {{
                      custInfo.CUS_POINT >= 15
                        ? "จำนวนสิทธิ์ครบแล้ว"
                        : "แสตมป์ของคุณไม่เพียงพอ"
                    }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div v-if="userIsRedeem">
            <div class="p-5">
              <div class="bg-white rounded-xl p-5 pb-10 shadow-md">
                <div class="text-center text-black">
                  <img
                    :src="userLinePictureUrl"
                    alt=""
                    class="rounded-full h-28 w-28 my-10 mx-auto"
                  />

                  <div class="text-2xl text-normal font-bold">
                    {{ userLineDisplayName }}
                  </div>
                  <div class="text-xl">คุณ{{ custInfo.CUS_FNAME }}</div>
                  <div
                    class="text-2xl font-bold font-extrabold text-blue-800 mb-5"
                  >
                    แลกสิทธิ์เรียบร้อยแล้ว <br />
                  </div>
                  <span class="font-bold text-lg" v-html="custRedeem.RW_NAME">
                  </span
                  ><br />
                  แลกเมื่อ {{ custRedeem.RDC_REDEEM_DATETIME }}
                  <br /><br /><br />
                  <a
                    :href="custRedeem.RW_SPECIAL_CODE"
                    target="_blank"
                    class="w-full px-7 py-3 text-xl rounded-xl shadow-lg bg-yellow-800 text-white"
                  >
                    กรอกรายละเอียดการเข้าร่วม
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- modal -->
      </div>
      <!-- end of modal -->
    </div>
  </div>
</template>
<script>
import liff from "@line/liff";
import { UserIcon, DeviceMobileIcon } from "@heroicons/vue/outline";
import router from "../router";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Service from "../service/service";
function unmask(maskedValue, mask) {
  let defaultTokens = ["#", "X", "S", "A", "a", "!"];
  let unmaskedValue = "";
  let isToken;
  for (let i = 0; i < maskedValue.length; i++) {
    isToken = false;
    for (let j = 0; j < defaultTokens.length; j++) {
      if (mask[i] == defaultTokens[j]) {
        isToken = true;
      }
    }
    if (isToken) {
      unmaskedValue += maskedValue[i];
    }
  }
  return unmaskedValue;
}
export default {
  name: "History",
  props: ["coupon"],
  data() {
    return {
      isLoading: true,
      userLineId: null,
      userLineDisplayName: null,
      userLinePictureUrl: null,
      userIsRegister: false,
      userIsRedeem: false,
      custInfo: null,
      custRedeem: null,
      couponSerial: "",
      couponDiscount: this.coupon,
      couponMessage: "",

      showModalAccept1: false,
      showModalAccept2: false,

      headerAccept1: null,
      headerAccept2: null,
      contentAccept1: null,
      contentAccept2: null,

      inputFName: "",
      inputLName: "",
      inputMobile: "",
      inputMobileRaw: "",
      inputShopName: "",
      inputShopBranch: "",
      inputProvince: "",
      inputAmount: "",
      disableInputFName: false,
      disableInputMobile: false,
      disableShopBranch: true,

      ListProvince: null,
      ListShop: null,
      ListBranche: null,
      ListReward: null,
      campaignInfo: null,
    };
  },
  components: {
    Loading,
    UserIcon,
    DeviceMobileIcon,
  },
  service: null,
  created() {
    this.service = new Service();
  },
  async mounted() {
    await liff.ready;
    if (!liff.isLoggedIn()) {
      liff.login();
    } else {
      this.isLoading = true;
      const profile = await liff.getProfile();
      this.userLineId = profile.userId;
      this.userLineDisplayName = profile.displayName;
      this.userLinePictureUrl = profile.pictureUrl;
      await this.service.getCampaignInfo().then((data) => {
        this.campaignInfo = data.campaignInfo;
      });
      await this.service.getReward().then((data) => {
        this.ListReward = data.rewardData;
      });
      await this.service.getCustomerInfo(this.userLineId).then((data) => {
        // console.log(rez.data);
        if (data.isRegisted === 1) {
          this.custInfo = data.custInfo;
          this.custRedeem = data.custRedeem;
          console.log(data.isRedeem);
          if (data.isRedeem === 1) {
            this.userIsRedeem = true;
          }

          this.userIsRegister = true;
        } else {
          this.userIsRegister = false;
          this.$swal
            .fire({
              html: "คุณยังไม่ได้ลงทะเบียน<br />กรุณาลงทะเบียนก่อนร่วมกิจกรรม",
              icon: "error",
              showCancelButton: false,
              confirmButtonColor: "#b91c1c",

              confirmButtonText: "ไปลงทะเบียน  &rarr;",
            })
            .then((result) => {
              if (result.isConfirmed) {
                router.push("/register");
              }
            });
        }
      });

      this.isLoading = false;
    }
  },
  methods: {
    liffCloseWindow() {
      liff.closeWindow();
    },
    goTo(r) {
      window.location.replace(r);
    },
    async goSubmit(w) {
      let isError = false;
      let txtError = "";
      if (this.custInfo.CUS_POINT < 15) {
        isError = true;
        txtError += "<li>แสตมป์ของท่านยังไม่พอต่อการแลกสิทธิ์</li>";
      }
      if (parseInt(w.RW_STOCK) - parseInt(w.REDEEM_COUNT) <= 0) {
        isError = true;
        txtError += "<li>สิทธิ์รอบนี้ถูกแลกครบแล้ว</li>";
      }

      if (!isError) {
        let formData = new FormData();
        formData.append("line_id", this.userLineId);
        formData.append("reward_id", w.RW_CODE);

        this.$swal
          .fire({
            html:
              "<h2 class='text-2xl font-bold text-blue-800'>" +
              w.RW_NAME +
              "</h2> จำนวน " +
              w.RW_STOCK +
              " รางวัล (จำกัด " +
              w.RW_STOCK * 2 +
              " ท่าน/รอบ)<br /><br /><span class='text-red-600 text-md'>ไม่สามารถแก้ไขวันที่ได้<br />หากกดยืนยันแลกรางวัลแล้ว</span>",
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#ff6600",
            cancelButtonColor: "#333",

            confirmButtonText: "ยืนยันการแลกรางวัล  &rarr;",
            cancelButtonText: "ยกเลิก",
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.service.submitRedeem(formData).then((rez) => {
                if (rez.data.isSuccess === 1) {
                  //this.userIsRedeem = true;

                  window.location.reload();
                } else {
                  this.$swal({
                    html: rez.data.msgError,
                    title: "พบข้อผิดพลาด",
                    icon: "error",
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonColor: "#991b1b",

                    cancelButtonText: "ตกลง",
                  });
                }
              });
            }
          });
      } else {
        this.$swal({
          html: "<ul>" + txtError + "</ul>",
          title: "พบข้อผิดพลาด",
          icon: "error",
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonColor: "#991b1b",
          cancelButtonText: "ตกลง",
        });
      }
    },
  },
};
</script>
